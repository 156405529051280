import * as React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import device from '@helpers/styled-breakpoints';
import smmlike from '../assets/images/portfolio/smm-like/SMMlike.png';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #edeef0;
`;
const Header = styled.div`
  position: fixed;
  height: 50px;
  width: 100%;
  background-color: #f1f1f1;
  padding: 16px 50px;
  box-shadow: 0 0 12px #989898;

  @media (max-width: 650px) {
    padding: 16px 35px;
  }
  @media ${device.tablet} {
    padding: 16px 4px;
  }
  @media (max-width: 500px) {
    padding: 16px 0;
  }

`;
const ImageDiv = styled.div`
  display: flex;
`;

const Image = styled.img`
  @media ${device.mobil} {
    width: 600px;
  }
  @media (max-width: 600px) {
    width: 350px;
  }

  @media ${device.tablet} {
    width: 750px;
  }

  @media ${device.desktop} {
    width: 1000px;
  }

`;

interface IProps {
}

const ArrowLeft = React.memo(({}: IProps) => {
  return (
    <svg
      transform="rotate(180 0 0)"
      cursor="pointer"
      width="80"
      height="15"
      viewBox="0 0 31 15"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.21094 8.70968L26.856 8.70968L22.6078 12.9329C22.1338 13.4042 22.132 14.1701 22.6038 14.6436C23.0755 15.1172 23.8423 15.119 24.3163 14.6477L30.6437 8.35742L30.6448 8.35627C31.1176 7.88498 31.1191 7.11659 30.6449 6.64373L30.6438 6.64258L24.3165 0.352257C23.8425 -0.118912 23.0757 -0.117279 22.6039 0.35637C22.1321 0.829898 22.1339 1.59581 22.6079 2.0671L26.856 6.29032L1.21094 6.29032C0.542136 6.29032 -1.19327e-06 6.83189 -1.25168e-06 7.5C-1.31009e-06 8.1681 0.542136 8.70968 1.21094 8.70968Z"
      />
    </svg>
  );
});

const SmmLikePages = () => {
  return (
    <Container>
      <Header>
        <Link to="/">
          <ArrowLeft />
        </Link>
      </Header>
      <ImageDiv>
        <Image src={smmlike} alt="1" />
      </ImageDiv>
    </Container>
  );
};

export default SmmLikePages;
