/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Props = {
  description?: string;
  lang?: string;
  meta?: any;
  title?: string;
};

const SEO = (props: Props) => {
  const { site: { siteMetadata} } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            HTMLAttributes { 
              lang
            }
            ogLocale
          }
        }
      }
    `,
  );

  const getMeta = (key) => props[key] || siteMetadata[key];

  return (
    <Helmet
      htmlAttributes={siteMetadata.HTMLAttributes}
      title={getMeta('title')}
      titleTemplate={`%s | ${getMeta('title')}`}
      meta={[
        {
          name: 'description',
          content: getMeta('description'),
        },
        {
          property: 'og:title',
          content: getMeta('title'),
        },
        {
          property: 'og:description',
          content: getMeta('description'),
        },
        {
          property: 'og:locale',
          content: getMeta('ogLocale'),
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: getMeta('title'),
        },
        {
          name: 'twitter:description',
          content: getMeta('description'),
        },
      ].concat(getMeta('meta') || [])}
    />
  );
}

export default SEO;
