import * as React from 'react';
import styled from 'styled-components';
import ArrowRight from '@components/elements/icons/arrow-right';
import device from '@helpers/styled-breakpoints';
import {Link} from 'gatsby';

interface IPropsButton {
  left?: string;
  bottom?: string;
  translateZ?: number;
  scale?: number;
}

const Button = styled.a<IPropsButton>`
  position: absolute;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: Geometria, sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding-right: 0;
  text-decoration: none;
  color: black;
  bottom: 14px;
  left: 20px;
  padding-left: 15px;

  svg {
    margin-left: 14px;
    width: 50px;
  }

  path {
    fill: black;
  }

  @media ${device.mobil} {
    font-size: 20px;
    bottom: 6px;
  }

  @media ${device.tablet} {
    font-size: 20px;
    padding-right: 80px;
    padding-left: 0;
  }

  @media ${device.desktop} {
    color: white;
    left: ${({left}) => left};
    bottom: ${({bottom}) => bottom};

    &:hover {
      svg {
        right: 0;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 15px;
      transition: right 200ms ease-out;
      width: 60px;
      margin-left: 0;
    }

    path {
      fill: white;
    }
  }

  @media ${device.extra} {
  }
`;

const NavLink = styled(Link)`
  position: absolute;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: Geometria, sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding-right: 0;
  text-decoration: none;
  color: black;
  bottom: 14px;
  left: 20px;
  padding-left: 15px;

  svg {
    margin-left: 14px;
    width: 50px;
  }

  path {
    fill: black;
  }

  @media ${device.mobil} {
    font-size: 20px;
    bottom: 6px;
  }

  @media ${device.tablet} {
    font-size: 20px;
    padding-right: 80px;
    padding-left: 0;
  }

  @media ${device.desktop} {
    color: white;
    left: 68px;
    bottom: 50px;

    &:hover {
      svg {
        right: 0;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 15px;
      transition: right 200ms ease-out;
      width: 60px;
      margin-left: 0;
    }

    path {
      fill: white;
    }
  }

  @media ${device.extra} {
  }
`;

interface IProps {
  left?: string;
  bottom?: string;
  href?: string;
  gatsbyLink?: boolean;
}

const ButtonShowMore = React.memo(({
  left,
  bottom,
  href = '#',
  gatsbyLink = false,
}: IProps) => {

  if (gatsbyLink === true) {
    return (
      <NavLink
        to="/smmlike"
      >
        Демо версия сайта
        <ArrowRight />
      </NavLink>
    );
  }

  return (
    <Button
      left={left}
      bottom={bottom}
      href={href}
      target="_blank"
    >
      Демо версия сайта
      <ArrowRight />
    </Button>
  );
});

export default ButtonShowMore;
