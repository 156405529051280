/* eslint-disable max-len */
import * as React from 'react';
import styled from 'styled-components';
import device from '@helpers/styled-breakpoints';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import {
  Tilt,
  PortfolioItemTitle,
  BackgroundImgWrap,
  WrapContent,
  StyledImageBackground,
} from '@components/blocks/portfolio-items/elements';
import ButtonShowMore from '@components/elements/button-show-more';
import {createCalcScale3d} from '@utils/math-addition';

const calcScale = createCalcScale3d(500, true);

const WrapImage = styled.div`
  position: absolute;
  left: auto;
  right: 0;
  bottom: auto;
  z-index: 5;
  
  @media ${device.mobil} {
    top: -20px;
  }
  
  @media ${device.tablet} {
    top: -24px;
  }
  
  @media ${device.desktop} {
    transform: ${calcScale(100)};
    top: auto;
    right: auto;
    left: 169px;
    bottom: -10px;
  }
  
  @media ${device.extra} {
  }
`;

const StyledImg = styled(Img)`
  display: none;
  
  @media ${device.mobil} {
    display: block;
    width: 400px;
  }
  
  @media ${device.tablet} {
    width: 500px;
  }
  
  @media ${device.desktop} {
    width: 615px;
  }
  
  @media ${device.extra} {
  }
`;

const WrapLines = styled.div`
  display: flex;
  margin-left: 30px;
  margin-top: 25px;
  transform: translateZ(50px);
`;

interface IPropsLine {
  opacity: number;
}

const Line = styled.span<IPropsLine>`
  margin: 0 4px;
  width: 50px;
  height: 4px;
  background-color: white;
  opacity: ${({ opacity }) => opacity};
`;

const BriorLogo = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 34px;
  margin-top: 100px;
  
  svg {
    height: 40px;
    width: 40px;
  }
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
    margin-top: 150px;

    svg {
      height: 50px;
      width: 50px;
    }
  }
  
  @media ${device.desktop} {
    margin-top: 50px;

    svg {
      height: 60px;
      width: 60px;
    }
  }
  
  @media ${device.extra} {
  }
`;

const LogoText = styled.div`
  margin-left: 10px;
  margin-bottom: -4px;
  font-size: 24px;
  font-family: Geometria, sans-serif;
  font-weight: 500;
  color: white;
`;

interface IProps {
}

const Brior = React.memo(({}: IProps) => {
  const data = useStaticQuery(graphql`
    query {
      skyscraper: file(relativePath: { eq: "portfolio/brior/skyscraper.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background: file(relativePath: { eq: "portfolio/brior/brior-background.png" }) {
        childImageSharp {
          fluid(maxHeight: 420, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Tilt>
      <BackgroundImgWrap>
        <StyledImageBackground fluid={data.background.childImageSharp.fluid} />
      </BackgroundImgWrap>
      <WrapImage>
        <StyledImg fluid={data.skyscraper.childImageSharp.fluid} />
      </WrapImage>
      <WrapContent>
        <PortfolioItemTitle>
          корпоративный
          <br />
          сайт Brior + k
        </PortfolioItemTitle>
        <WrapLines>
          <Line opacity={1} />
          <Line opacity={0.4} />
          <Line opacity={0.2} />
          <Line opacity={0.1} />
        </WrapLines>
        <BriorLogo>
          <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="white"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M55.459 29.212C57.1344 30.8878 59.9998 29.7011 59.9998 27.3307V27.3307C59.999812.2361 47.7652 -9.91821e-05 32.6727 -9.91821e-05H30.0007H26.2507H2.65982C0.294822 -9.91821e-05 -0.889344 2.85948 0.782739 4.5324L26.2507 30.0032L30.0007 33.7536L55.459 59.2153C57.1344 60.8916 59.9998 59.7045 59.9998 57.3341C59.9998 42.2399 47.7652 30.0032 32.6727 30.0032H30.0007V3.75032L55.459 29.212Z"
            />
          </svg>

          <LogoText>
            BRIOR+K
          </LogoText>
        </BriorLogo>
        <ButtonShowMore left="68px" bottom="50px" href="http://brior.webstap.ru/" />
      </WrapContent>
    </Tilt>
  );
});

export default Brior;
