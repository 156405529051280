import * as React from 'react';
import {useState} from 'react';
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';

interface IProps {
  children: any;
}

const Slider = ({children}: IProps) => {
  const [components, setComponents] = useState(children);
  return (
    <Wrapper>
      <Carousel
        showArrows={false}
        pagination={false}
        isRTL={false}
      >
        {components.map((foo) => {
          return (
            <Content>{foo}</Content>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default Slider;

const Wrapper = styled.div`
  width: 350px;
  @media (max-width: 360px) {
    width: 280px;
  }
`;

const Content = styled.div`
  width: 350px;
  height: 550px;
`;
