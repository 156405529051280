import * as React from 'react';
import styled, {css} from 'styled-components';
import WaveFooter from '@components/elements/wave-footer';
import backgroundPattern from '@assets/images/background-pattern.png';
import {Button} from '@components/inputs';
import Modal from '@components/modals/base-modal';
import {ModalTitle, WrapWithCopyByClick} from '@components/modals/modal-elements';
import FormOrder from '@components/forms/order';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import {Telegram, Whatsapp} from '@components/elements/icons/contacts';
import NavbarItemsWebstap from '@components/blocks/navbar-items-webstap';
// import MainLogo from '@components/images/main-logo';
import {LogoMiniBright} from '@components/elements/icons/webstap-logo';
import * as logos from '@components/elements/icons/webstap-logo';

const Wrap = styled.div`
  position: relative;
  height: 800px;
  background: url(${backgroundPattern}) repeat, linear-gradient(to top left, #1E1464, #541C64);
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  
  @media (min-width: 1024px) {
    height: 100vh;
  }
`;

const WrapImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
`;

const cssForPseoudoCircles = css`
  content: '';
  display: block;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  
  @keyframes circles-hover {
    0% {
      width: 130px;
      height: 130px;
      opacity: 0;
      border: 1px solid rgba(255,255,255,0.75);
    }
    50% {
      opacity: 1;    
    }
    100% {
      width: 210px;
      height: 210px;
      opacity: 0;
      border: 2px solid rgba(255,255,255,0.75);
    }
  }
`;

const WrapImage = styled.div`
  position: relative;
  width: 110px;
  height: 110px;
  cursor: pointer;
  margin: 12px;

  &:before, &:after {
    animation-name: none;
    ${cssForPseoudoCircles}
  }
  
  &:after {
    animation-delay: 1000ms;  
  }
  
  &:hover:before, &:hover:after {    
    animation-name: circles-hover;
  }
  
  @media (min-width: 414px) {
    margin: 0 25px;
    width: 130px;
    height: 130px;
  }
  
  @media (min-width: 576px) {
    margin: 0 50px;
  }
  
  @media (min-width: 768px) {
    margin: 0 100px;
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  position: relative;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  font-family: 'Geometria', sans-serif;
  font-weight: 700;
  font-size: 35px;
  margin-top: 50px;
  
  &:after {
    content: '';
    display: block;
    margin: 10px auto 0 auto;
    width: 120px;
    height: 2px;
    background: #fff;
  }
  
  @media (min-width: 1024px) {
    margin-top: 0;
  }
`;

const FooterCopyright = styled.div`
  text-align: center;
  color: white;
  padding: 15px 0;
  font-family: 'Geometria', sans-serif;
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;  
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`;

const WrapIcons = styled.div`
  display: flex;
  width: 100px;
  justify-content: space-between;
  margin: 0 auto 30px auto;
`;

const NavList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  right: 0;
  bottom: 30px;
  z-index: 100;
  flex-direction: column;
  justify-content: right;
  font-family: 'Roboto', sans-serif;
  display: none;
  
  a {
    text-align: right;
  }
  
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const WrapFooter = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  bottom: 0;
  z-index: 100;
  height: 170px;
  width: 100%;
  max-width: 980px;
`;

const LogoWrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  z-index: 100;
  width: 300px;
  
  @media (min-width: 1024px) {
    transform: translateX(0);
    left: 0;
    bottom: 30px;
  }
`;

const WrapContent = styled.div`
  position: relative;
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  margin-top: -300px;
`;

const StyledLogoFullDark = styled(logos.LogoFullDark)`
  display: block;
  margin: 0 auto 20px auto;
`;

type TContactModal = 'phone' | 'mail' | '';

interface IProps {
  stopAnimation: boolean;
  enableAnimation: boolean;
  isDesctop: boolean;
}

const Contacts = React.memo(({
  stopAnimation,
  enableAnimation,
  isDesctop,
}: IProps) => {
  const [isOpenContactsFormModal, setIsOpenContactsFormModal] = React.useState<boolean>(false);
  const [currentContactTypeModal, setCurrentContactTypeModal] = React.useState<TContactModal>('');

  const onOpenModal = () => {
    setIsOpenContactsFormModal(true);
  };

  const onCloseModal = () => {
    setIsOpenContactsFormModal(false);
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  const handlerForSetType = (type: TContactModal) => () => {
    setCurrentContactTypeModal(type);
  };

  const data = useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "contacts/phone.png" }) {
        childImageSharp {
          fluid(maxHeight: 461, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mail: file(relativePath: { eq: "contacts/mail.png" }) {
        childImageSharp {
          fluid(maxHeight: 461, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const isOpenPhoneModal = currentContactTypeModal === 'phone';
  const isOpenMailModal = currentContactTypeModal === 'mail';

  return (
    <Wrap>
      <WaveFooter paused={stopAnimation || !isDesctop} />
      <FooterCopyright>© {(new Date()).getFullYear()} Webstap</FooterCopyright>

      <WrapContent>
        <Content>
          <Title>Контакты</Title>
          <WrapFooter>
            <LogoWrap>
              <LogoMiniBright />
            </LogoWrap>
          </WrapFooter>
          <Button onClick={onOpenModal} theme="secondary">Связаться с нами</Button>

          <WrapImages>
            <WrapImage onClick={handlerForSetType('phone')}><Img fluid={data.phone.childImageSharp.fluid} /></WrapImage>
            <WrapImage onClick={handlerForSetType('mail')}><Img fluid={data.mail.childImageSharp.fluid} /></WrapImage>
          </WrapImages>
        </Content>

        <Modal enableAnimation={enableAnimation} onClose={onCloseModal} isOpen={isOpenContactsFormModal}>
          <ModalTitle>Контактная информация</ModalTitle>
          <FormOrder onSubmit={onSubmit} />
        </Modal>

        <Modal enableAnimation={enableAnimation} onClose={handlerForSetType('')} isOpen={isOpenPhoneModal}>
          <StyledLogoFullDark width="85%" />

          <WrapWithCopyByClick fixMargin textForCopy="+79897489317">
            +7 989 748 93 17
          </WrapWithCopyByClick>
          <Button href="tel:79897489317" margin="20px auto 10px auto" theme="tertiary" width="80%">
            Позвонить
          </Button>
          <WrapIcons>
            <a
              rel="noopener noreferrer"
              href="https://t.me/semenovyar"
              target="_blank"
            >
              <Telegram width="40px" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://api.whatsapp.com/send?phone=79385225429"
              target="_blank"
            >
              <Whatsapp width="40px" />
            </a>
          </WrapIcons>
        </Modal>

        <Modal enableAnimation={enableAnimation} onClose={handlerForSetType('')} isOpen={isOpenMailModal}>
          <StyledLogoFullDark width="85%" />

          <WrapWithCopyByClick fixMargin textForCopy="info@webstap.ru">
            info@webstap.ru
          </WrapWithCopyByClick>
          <WrapWithCopyByClick theme="transparent" textForCopy="info@webstap.ru">
            <Button margin="20px auto 0 auto" theme="primary" width="80%">
              Скопировать
            </Button>
          </WrapWithCopyByClick>

          <Button href="mailto:info@webstap.ru" margin="10px auto 40px auto" theme="tertiary" width="80%">
            Написать
          </Button>
        </Modal>
      </WrapContent>
    </Wrap>
  );
});

export default Contacts;
