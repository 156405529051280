import * as React from 'react';
import styled from 'styled-components';
// import throttle from 'lodash.throttle';
// import { getPercentOfProportion } from '@utils/math-addition';
// import withMoveAnimateByPercent from '@hoc/with-move-animate-by-percent';
import RateElement from '@components/elements/rate-element';
import {
  Blog,
  BussinesCard,
  CorporateSite,
  LandingPage,
  OnlineStore,
  OnlineStorefront,
  Other,
} from '@components/elements/icons/rate-icons';
import OrderTypeComponent, {
  Order,
  OrderType,
  mapOrderSectionTitles,
  mapOrderTypeTitles,
  mapOrderRates,
} from '@components/elements/order-type';
import {
  ButtonBack,
} from '@components/elements/order-elements';
import Modal from '@components/modals/base-modal';
import {
  ModalTitle,
  ModalSubTitle,
} from '@components/modals/modal-elements';
import FormOrder from '@components/forms/order';
import Slider from '@components/slider/slider';

const Wrap = styled.div<{enableAnimation: boolean}>`
  position: relative;
  overflow: ${({enableAnimation}) => enableAnimation && 'hidden'};
  padding: 15px 15px 50px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (min-width: 576px) {
    padding: 15px 20px 50px 20px;
  }
  
  @media (min-width: 768px) {
    padding: 15px 3vw 50px 3vw;
  }
  
  @media (min-width: 1024px) {
    padding: ${({enableAnimation}) => !enableAnimation && '100px 0 !important'};
    height: ${({enableAnimation}) => enableAnimation && '100vh'};
  }
  
  @media (min-width: 1308px) {
    padding: 15px 130px 50px 130px;    
  }
`;

const BackgroundLayer = styled.div<{enableAnimation: boolean}>`
  position: absolute;
  background-color: ${({enableAnimation}) => enableAnimation && '#f4f4f4'};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
`;

const Circle = styled.div`
  position: absolute;
  width: 482px;
  height: 482px;
  left: -95px;
  bottom: 40px;
  border-radius: 50%;
  background: linear-gradient(180deg, #551C64 0%, rgba(27, 20, 100, 0.9) 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  
  &:before {
    content: '';
    position: absolute;
    width: 482px;
    height: 482px;
    top: -20px;
    left: 0;
    border-radius: 50%;
    background: linear-gradient(180deg, #C165DD 25.6%, #5C27FE 100%);
    box-shadow: 2px 2px 30px rgba(92, 39, 254, 0.4);
  }
`;

const Rectangle = styled.div`
  position: absolute;
  right: -38px;
  top: -40px;
  width: 443px;
  height: 323px;
  background: linear-gradient(180deg, #551C64 0%, rgba(27, 20, 100, 0.9) 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 60px;
  transform: rotateZ(-45deg);
  
  &:before {
    content: '';
    position: absolute;
    border-radius: 60px;
    top: -30px;
    width: 443px;
    height: 323px;
    background: linear-gradient(180deg, #C165DD 25.6%, #5C27FE 100%);
    box-shadow: 2px 2px 30px rgba(92, 39, 254, 0.4);
  }
`;

// const MovedByXCircle = withMoveAnimateByPercent(Circle, 10);
// const MovedByXRectangle = withMoveAnimateByPercent(
//   Rectangle, -10, null, null, 'rotate(-45deg)',
// );

const Title = styled.h2`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #4A599C;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  height: 48px;

  @media (max-width: 576px) {
    font-size: 26px;
  }
  
  span {
    margin-left: 10px;
    display: block;
  }
 
  @media (min-width: 576px) {
    font-size: 36px;  
  }
  
  @media (min-width: 1024px) {
    font-size: 40px;
    justify-content: flex-start;
  }
`;

interface IRateTile {
  gridTemplate?: string;
}

const RateTile = styled.div<IRateTile>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  grid-auto-rows: max-content;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;    
  }
`;

const ContentWrap = styled.div`
  margin: 0 auto;
  max-width: 780px;
  
  @media (min-width: 1024px) {
    max-width: 1300px;
  }
`;

const RateTileHeightByContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  grid-auto-rows: max-content;
  
  @media (min-width: 576px) {
  }
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    //grid-template-columns: 1fr 1fr 1fr;
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ComponentMobile = styled.div`
  display: none;
  
  @media (max-width: 576px) {
    display: block;
  }
`;

const ComponentDesktop = styled.div`
  display: block;
  
  @media (max-width: 576px) {
    display: none;
  }
`;

interface IProps {
  enableAnimation: boolean;
}

const Rate = React.memo(({enableAnimation}: IProps) => {
  // const [mouseXPercent, setMouseXPercent] = React.useState();
  const [orderSection, setOrderSection] = React.useState<Order | ''>('');
  const [orderType, setOrderType] = React.useState<OrderType | ''>('');
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  // const mouseMoveHandler = React.useRef(throttle((event: MouseEvent) => {
  //   const mouseX = event.screenX;
  //   const maxMouseX = window.screen.width;
  //   const mouseXPercentResult = getPercentOfProportion(mouseX, maxMouseX) / 100;
  //   setMouseXPercent(mouseXPercentResult);
  // }, 50)).current;

  // React.useEffect(() => {
  //   window.addEventListener('mousemove', mouseMoveHandler);
  //
  //   return () => {
  //     window.removeEventListener('mousemove', mouseMoveHandler);
  //   };
  // }, []);

  const onClickOrderRate = (order: Order) => () => {
    setOrderSection(order);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const onOpenModal = () => {
    setIsOpenModal(true);
  };

  const onSetOrderType = (type: OrderType) => () => {
    setOrderType(type);
    onOpenModal();
  };

  const showOrderSections = !orderSection;
  const showOrderTypes = orderSection;

  const sectionName = mapOrderSectionTitles[orderSection] || '';
  const cost = orderSection && orderType ? mapOrderRates[orderSection][orderType].cost : '';
  const orderTypeName = mapOrderTypeTitles[orderType] || '';

  const onSubmit = (values: any) => {
    console.log(values);
  };

  return (
    <Wrap enableAnimation={enableAnimation}>
      <ContentWrap>
        <BackgroundLayer>
          <Circle />
          <Rectangle />

          {/* <MovedByXCircle percent={mouseXPercent} /> */}
          {/* <MovedByXRectangle percent={mouseXPercent} /> */}
        </BackgroundLayer>

        {showOrderSections && (
          <>
            <Title>Сколько стоит?</Title>
            <ComponentDesktop>
              <RateTile>
                <RateElement
                  onClick={onClickOrderRate('businessCard')}
                  title={mapOrderSectionTitles.businessCard}
                  cost="6 000"
                >
                  <BussinesCard />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('landing')}
                  title={mapOrderSectionTitles.landing}
                  cost="15 000"
                >
                  <LandingPage />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('corporateSite')}
                  title={mapOrderSectionTitles.corporateSite}
                  cost="20 000"
                >
                  <CorporateSite />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('cabinet')}
                  title={mapOrderSectionTitles.cabinet}
                  cost="30 000"
                >
                  <Blog />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('onlineStore')}
                  title={mapOrderSectionTitles.onlineStore}
                  cost="80 000"
                >
                  <OnlineStore />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('onlineService')}
                  title={mapOrderSectionTitles.onlineService}
                  cost="100 000"
                >
                  <OnlineStorefront />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('mobileApplication')}
                  title="Мобильное приложение"
                  cost="100 000"
                >
                  <Other />
                </RateElement>
              </RateTile>
            </ComponentDesktop>
            <ComponentMobile>
              <Slider>
                <RateElement
                  onClick={onClickOrderRate('businessCard')}
                  title={mapOrderSectionTitles.businessCard}
                  cost="6 000"
                >
                  <BussinesCard />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('landing')}
                  title={mapOrderSectionTitles.landing}
                  cost="15 000"
                >
                  <LandingPage />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('corporateSite')}
                  title={mapOrderSectionTitles.corporateSite}
                  cost="20 000"
                >
                  <CorporateSite />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('cabinet')}
                  title={mapOrderSectionTitles.cabinet}
                  cost="30 000"
                >
                  <Blog />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('onlineStore')}
                  title={mapOrderSectionTitles.onlineStore}
                  cost="80 000"
                >
                  <OnlineStore />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('onlineService')}
                  title={mapOrderSectionTitles.onlineService}
                  cost="100 000"
                >
                  <OnlineStorefront />
                </RateElement>
                <RateElement
                  onClick={onClickOrderRate('mobileApplication')}
                  title="Мобильное приложение"
                  cost="100 000"
                >
                  <Other />
                </RateElement>
              </Slider>
            </ComponentMobile>
          </>
        )}
        {showOrderTypes && (
          <>
            <ButtonBack
              onClick={() => setOrderSection('')}
            />
            <Title>
              <span>
                {mapOrderSectionTitles[orderSection]}
              </span>
            </Title>
            <ComponentDesktop>
              <RateTileHeightByContent>
                <OrderTypeComponent
                  orderSection={orderSection}
                  orderType="lite"
                  onClick={onSetOrderType('lite')}
                />
                <OrderTypeComponent
                  orderSection={orderSection}
                  orderType="standard"
                  onClick={onSetOrderType('standard')}
                />
                <OrderTypeComponent
                  orderSection={orderSection}
                  orderType="premium"
                  onClick={onSetOrderType('premium')}
                />
              </RateTileHeightByContent>
            </ComponentDesktop>
            <ComponentMobile>
              <RateTileHeightByContent>
                <Slider>
                  <OrderTypeComponent
                    orderSection={orderSection}
                    orderType="lite"
                    onClick={onSetOrderType('lite')}
                  />
                  <OrderTypeComponent
                    orderSection={orderSection}
                    orderType="standard"
                    onClick={onSetOrderType('standard')}
                  />
                  <OrderTypeComponent
                    orderSection={orderSection}
                    orderType="premium"
                    onClick={onSetOrderType('premium')}
                  />
                </Slider>
              </RateTileHeightByContent>
            </ComponentMobile>
          </>
        )}

        <Modal onClose={onCloseModal} isOpen={isOpenModal}>
          <ModalTitle>{sectionName}</ModalTitle>
          <ModalSubTitle>
            тариф {orderTypeName} <strong>от ({cost})</strong>
          </ModalSubTitle>
          <FormOrder onSubmit={onSubmit} />
        </Modal>
      </ContentWrap>
    </Wrap>
  );
});

export default Rate;
