import * as React from 'react';
import styled from 'styled-components';
import device from '@helpers/styled-breakpoints';
import {
  BackgroundImgWrap,
  PortfolioItemTitle as PortfolioItemTitleElement,
  Tilt,
  WrapContent,
  StyledImageBackground,
} from '@components/blocks/portfolio-items/elements';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import ButtonShowMore from '@components/elements/button-show-more';
import {createCalcScale3d} from '@utils/math-addition';

const calcScale = createCalcScale3d(500, true);

const WrapLogo = styled.div`
  margin-top: 30px;
  margin-left: 30px;
  position: absolute;
  bottom: 80px;

  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
    bottom: 100px;
  }
  
  @media ${device.desktop} {
    position: static;
    width: 262px;
    height: 82px;
  }
  
  @media ${device.extra} {
  }
`;

const WrapImage = styled.div`
  position: absolute;
  right: 25px;
  bottom: -25px;
  width: 420px;
  
  @media ${device.mobil} {
    right: -150px;
    bottom: 44px;
  }
  
  @media ${device.tablet} {
    right: -100px;
    bottom: 44px;
  }
  
  @media ${device.desktop} {
    top: auto;
    transform: ${calcScale(150)};
    right: 25px;
    bottom: -25px;
  }
  
  @media ${device.extra} {
  }
`;

const PortfolioItemTitle = styled(PortfolioItemTitleElement)`
  font-size: 20px;
  margin-left: 30px;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    font-size: 34px;
  }
  
  @media ${device.extra} {
  }
`;

const StyledImg = styled(Img)`
  display: none;
  
  @media ${device.mobil} {
    display: block;
    width: 290px;
  }
  
  @media ${device.tablet} {
    width: 350px;
  }
  
  @media ${device.desktop} {
    width: auto;
  }
  
  @media ${device.extra} {
  }
`;

const StyledImageLogo = styled(Img)`
  width: 200px;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    width: auto;
  }
  
  @media ${device.extra} {
  }
`;

interface IProps {
}

const SmmLike = React.memo(({}: IProps) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "portfolio/smm-like/background.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iphone: file(relativePath: { eq: "portfolio/smm-like/phone-origin.png" }) {
        childImageSharp {
          fluid(maxHeight: 475, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "portfolio/smm-like/logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 79, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Tilt marginTop="150px">
      <BackgroundImgWrap>
        <StyledImageBackground fluid={data.background.childImageSharp.fluid} />
      </BackgroundImgWrap>

      <WrapContent>
        <PortfolioItemTitle>
          Дизайн landing <br />
          page для агентства <br />
          SMM-like
        </PortfolioItemTitle>
        <WrapLogo>
          <StyledImageLogo fluid={data.logo.childImageSharp.fluid} />
        </WrapLogo>
        <ButtonShowMore gatsbyLink />
      </WrapContent>

      <WrapImage>
        <StyledImg fluid={data.iphone.childImageSharp.fluid} />
      </WrapImage>

    </Tilt>
  );
});

export default SmmLike;
