import * as React from 'react';
import styled, { css } from 'styled-components';
import {NavItem} from '@components/blocks/navbar';
import useResponsive from '@hooks/responsive';
import {IndexPageContext} from '@components/context/index-provider';

const navItems = [
  {key: '1', position: 0, to: '/', label: 'Главная', isActive: false},
  {key: '2', position: 1, to: '#portfolio', label: 'Кейсы', isActive: false},
  {key: '3', position: 2, to: '#rate', label: 'Стоимость', isActive: false},
  {key: '4', position: 3, to: '#contacts', label: 'Контакты', isActive: false},
];

const NavContainer = styled.div<{isBgColorWhite: boolean}>`
  display: flex;
  flex-direction: column;
  width: 200px;
  color: white;
  transition: .75s;
  
  ${(props) => props.isBgColorWhite && css`
     filter: invert(1);
  `}
`;

const NavItemLink = styled.a<{isActive: boolean}>`
  text-decoration: none;
  font-weight: 400;
  float: right;
  color: white;
  line-height: 11.72px;
  font-size: 16px;
  padding: .5em 0;
  
  hr {
    border: 0;
    height: 1px;
    width: 47px;
    margin-left: 20px;
    transition: .3s;
    float: right;
    background: white;
  }
  
  ${(props) => props.isActive && css`
    font-weight: 500;
    hr {
      width: 84px;
    }
  `}
`;

interface IProps {
  getScrollPercent: (value: number) => number;
  changeSlide: {disable: boolean, timerId: null};
  refIntro: HTMLDivElement;
  refPortfolio: HTMLDivElement;
  refRate: HTMLDivElement;
}

const SlidesSwitcherItems = React.memo(({
  getScrollPercent,
  changeSlide,
  refIntro,
  refPortfolio,
  refRate,
}: IProps) => {
  const {setPageScrollPercentByIndex, enableAnimation} = React.useContext(IndexPageContext);
  const isDesktop = useResponsive('(min-width: 1024px)');
  const isSwitcherOnCenter = useResponsive('(min-width: 1550px)');
  const isDesktopRef = React.useRef(isDesktop);
  isDesktopRef.current = isDesktop;
  const [scroll, setScroll] = React.useState(0);
  let isBgColorWhite = false;

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (enableAnimation) {
    let i = true;
    navItems.reverse().forEach((e) => {
      if (getScrollPercent(e.position - 1) === 1 && i) {
        e.isActive = true;
        i = false;
      } else if (getScrollPercent(0) === 0) {
        navItems[(navItems.length - 1)].isActive = true;
        navItems[(navItems.length - 2)].isActive = false;
        i = false;
      } else {
        e.isActive = false;
      }
    });
    navItems.reverse();
    isBgColorWhite = !(getScrollPercent(0) === 0 || getScrollPercent(2) === 1);
  } else {
    // isSwitcherOnCenter equals one if its true (if false its equals zero)
    // and i minus this value because when switcher on center u need to change isActive and isBgColorWhite faster
    if (scroll > (refIntro.current.scrollHeight + refPortfolio.current.scrollHeight + refRate.current.scrollHeight + 190 - (isSwitcherOnCenter ? document.documentElement.clientHeight / 2 : 126))) {
      navItems[0].isActive = false;
      navItems[1].isActive = false;
      navItems[2].isActive = false;
      navItems[3].isActive = true;
      isBgColorWhite = false;

    } else if (scroll > (refIntro.current.scrollHeight + refPortfolio.current.scrollHeight + 140 - (isSwitcherOnCenter ? document.documentElement.clientHeight / 2 : 126))) {
      navItems[0].isActive = false;
      navItems[1].isActive = false;
      navItems[2].isActive = true;
      navItems[3].isActive = false;
      isBgColorWhite = true;

    } else if (scroll > (refIntro.current.scrollHeight - (isSwitcherOnCenter ? document.documentElement.clientHeight / 2 : 126))) {
      navItems[0].isActive = false;
      navItems[1].isActive = true;
      navItems[2].isActive = false;
      navItems[3].isActive = false;
      isBgColorWhite = true;

    } else {
      navItems[0].isActive = true;
      navItems[1].isActive = false;
      navItems[2].isActive = false;
      navItems[3].isActive = false;
      isBgColorWhite = false;
    }
  }
  const onClick = (position) => (e) => {
    e.preventDefault();
    if (!isDesktopRef.current || !enableAnimation) {
      const needScroll = (position === 3 ? refIntro.current.scrollHeight + refPortfolio.current.scrollHeight + refRate.current.scrollHeight + 190 : position === 2 ? refIntro.current.scrollHeight + refPortfolio.current.scrollHeight + 140 : position === 1 ? refIntro.current.scrollHeight : 0);
      window.scrollTo({
        behavior: 'smooth',
        top: needScroll,
      });
    } else if (!changeSlide.disable) {
      setPageScrollPercentByIndex(position, 0);
    }
  };

  return (

    <NavContainer isBgColorWhite={isBgColorWhite}>
      {navItems.map(({to, label, key, position, isActive}) => (
        <NavItem onClick={onClick(position)} key={key}>
          <NavItemLink isActive={isActive} href={to}>
            {label} <hr />
          </NavItemLink>
        </NavItem>
      ))}
    </NavContainer>
  );
});

export default SlidesSwitcherItems;
